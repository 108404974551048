:root {
  --color-white: "#000";
  --bg-color: "transparent";
  --label-fontSize:'14px';
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:disabled {
  background-color: rgb(206, 206, 206) !important;
  color: gray !important;
}

.ant-calendar-input-wrap {
  display: none !important;
}

.main-row {
  background-color: rgb(245, 247, 251);
 /* // background-color: #103A71; */
}
.main-col {
  /* background-color: white; */
  background-color: var(--bg-color);
  min-height: 100vh;
  padding: 30px 100px;
}
.text-success {
  text-align: center;
  color: green;
}

.styled-button {
  margin: 10px;
  padding: 0 30px;
  background: #4267b2;
  border: none;
  color: white;
  border: transparent 1px solid;
}

.styled-button:hover {
  border: #4267b2 1px solid;
  color: #4267b2;
}

.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.upload-file_container {
  display: flex;
  width: 100%;
  padding: 10px 0;
  justify-content: space-between;
  align-items: center;
}
.upload-file_container-box span {
  margin: 0 20px;
}
.upload-file_container-trash {
  cursor: pointer;
  color: red;
}

.files-list-item {
  display: flex;
  width: 100%;
  width: 300px;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: 0.4s ease-in-out all;
}
.files-list-item:hover .files-list-item_container {
  color: #4267b2;
  border-color: #4267b2;
}

.files-list-item:hover .files-list-item_trash {
  color: black;
  opacity: 1;
}

a.files-list-item_container {
  transition: 0.4s ease-in-out all;
  display: flex;
  height: 100%;
  color: black;
}

.files-list-item_box {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.files-list-item_icon {
  font-size: 2rem;
  min-width: 60px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.files-list-item_name {
  overflow: hidden;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.files-list-item_name {
  font-size: 0.9rem;
  line-height: 16px;
}

.files-list-item_size {
  color: rgba(0, 0, 0, 0.5);
}

.files-list-item_trash {
  transition: 0.4s ease-in-out all;
  font-size: 1rem;
  opacity: 0;
  cursor: pointer;
}
.files-list-item_trash:hover {
  color: red !important;
}
.ant-upload-list {
  display: flex;
  flex-wrap: wrap;
}

.ant-upload-list-text-container {
  margin: 0 5px;
}
.ant-form-item-label label{
  color: var(--color-white);
  font-size: var(--label-fontSize);
}
